.deviceBreakdown {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.title-box {
  display: flex;
  flex-direction: column;
  overflow-wrap: break-word;
  max-width: 100%;
}

.title {
  font-size: 3.5rem;
  color: #1340a0;
}

.channel-title {
  font-size: 2rem;
  color: #1340a0;
}

.exp-title {
  font-size: 2rem;
  color: #1340a0;
}

.sub-title {
  font-size: 2rem;
}

.embed-link {
  font-size: 1rem;
}

.datepicker-container {
  display: flex;
}
.header {
  display: flex;
}
/* @media screen and (min-width:690px) { */
@media screen and (min-width: 800px) {
  .header {
    flex-direction: row;
  }

  .title-box {
    text-align: left;
    justify-content: flex-start;
  }

  .datepicker-container {
    justify-content: flex-end;
  }
}

/* @media screen and (max-width:689px) { */
@media screen and (max-width: 799px) {
  .header {
    flex-direction: column;
  }

  .title-box {
    text-align: center;
    justify-content: center;
  }

  .datepicker-container {
    justify-content: center;
    margin-top: 1rem;
  }
}

.breakdowns .col {
  display: flex;
  justify-content: center;
}

.devices {
  display: flex;
  flex-direction: column;

  h3 {
    align-self: center;
    text-align: center;
  }
}